import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  ArrowIcon,
  BackArrowIcon,
  CloseIcon,
  FallbackClipArt,
  FilterIcon,
  LinkIcon,
  SearchIcon,
  TickIcon
} from "../../Assets/assets";
import Button from "../../Components/Button/Button";
import Header from "../../Components/Header/Header";
import { isValidObject } from "../../Services/validators";
import { complaintsCategory } from "../../Utils/constants";
import "./Complaints.css";
import {
  setFilter,
  setSearchKey,
  setSortBy
} from "../../Redux/complaints/action";

function Complaints(props) {
  const [showFilter, setShowFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({
    category: [],
    dateRange: {
      from: null,
      to: null
    },
    issueRaised: [],
    PCTNo: [],
    status: []
  });

  useEffect(() => {
    setSelectedFilter(props.complaints.filter);
  }, [props.complaints.filter]);

  const isFilterApplied = useMemo(() => {
    const filterData = props.complaints.filter;
    return (
      filterData?.status?.length > 0 ||
      filterData?.category?.length > 0 ||
      filterData?.dateRange?.from !== null ||
      filterData?.dateRange?.to !== null ||
      filterData.issueRaised?.length > 0 ||
      filterData?.PCTNo?.length > 0
    );
  }, [props.complaints.filter]);

  const getPctNumberData = (data) => {
    if (data && isValidObject(data)) {
      return Object.values(data).sort((a, b) => a.PCTNo - b.PCTNo);
    } else {
      return [];
    }
  };

  const filterData = {
    category: {
      title: "Category",
      value: complaintsCategory
    },
    issueRaised: {
      title: "Issue Raised"
    },
    dateRange: {
      title: "Date Range",
      value: {
        from: null,
        to: null
      }
    },
    PCTNo: {
      title: "PCT No",
      value: getPctNumberData(props.locations.data)
    },
    status: {
      title: "Status",
      value: ["Open", "Closed", "Under review"]
    }
  };

  function resetFilter() {
    let data = {
      dateRange: {
        from: null,
        to: null
      },
      category: [],
      issueRaised: [],
      PCTNo: [],
      status: []
    };
    setSelectedFilter(data);
    props.setFilter(data);
  }

  return (
    <div className="inherit-parent-height inherit-parent-width overflow-hidden">
      <Header
        title={
          showFilter
            ? "Filter"
            : window.location.pathname.includes("linkComplaint")
            ? "Link to existing complaints"
            : "Customer Support"
        }
        backOnClick={
          showFilter
            ? () => {
                resetFilter();
                setShowFilter(false);
              }
            : window.location.pathname.includes("linkComplaint")
            ? () => {
                props.navigate(-1);
              }
            : false
        }
        logoutOnClick={props.logoutClick}
      />
      {!showFilter ? (
        <>
          <div className="display-flex flex-justify-content-center flex-align-items-center border-bottom-1px margin-right-large margin-left-large margin-left-large margin-top-default">
            <input
              style={{
                width: "100%"
              }}
              className={`font-size-medium inherit-parent-height border-none font-family-RHD-regular padding-right-default padding-top-default padding-bottom-default inherit-parent-width`}
              value={props.complaints.searchKey?.toUpperCase()}
              autoFocus={true}
              onChange={(event) => {
                props.setSearchKey(event.target.value);
              }}
              placeholder="Complaint ID"
              title="Search bar"
              data-cy="search-input-field"
            />
            <div
              style={{
                width: "16px",
                height: "16px"
              }}
              data-cy={
                props.complaints?.searchKey?.length > 0
                  ? "search-clear-button"
                  : "search-button"
              }
              onClick={() => {
                if (props.complaints?.searchKey?.length > 0) {
                  props.setSearchKey("");
                }
              }}
            >
              {props.complaints?.searchKey?.length < 1 ? (
                <SearchIcon width={16} height={16} />
              ) : (
                <CloseIcon width={16} height={16} />
              )}
            </div>
          </div>
          <div className="display-flex flex-align-items-center flex-justify-content-space-between padding-large">
            <div
              className="display-flex flex-align-items-center"
              data-cy={
                props.complaints.sortBy === "desc"
                  ? "filter-oldest"
                  : "filter-newest"
              }
              onClick={() => {
                if (props.complaints.sortBy === "desc") {
                  props.setSortBy("asc");
                } else {
                  props.setSortBy("desc");
                }
              }}
            >
              <div className="padding-right-default font-size-medium">
                {props.complaints.sortBy === "desc" ? "Oldest" : "Newest"}
              </div>
              <div
                className={` display-flex flex-align-items-center  ${
                  props.complaints.sortBy === "desc"
                    ? "rotate-90"
                    : "rotate-270"
                } `}
              >
                <BackArrowIcon />
              </div>
            </div>

            <div
              className="display-flex flex-align-items-center position-relative "
              data-cy={"complaints-filter-button"}
              onClick={() => {
                setShowFilter(true);
              }}
            >
              <div className=" display-flex flex-align-items-center font-size-medium padding-right-medium">
                Filter
              </div>
              <FilterIcon />
              {isFilterApplied && (
                <div
                  style={{
                    height: "8px",
                    width: "8px",
                    top: "2px",
                    right: "0px"
                  }}
                  className="background-color-primary border-radius-16px position-absolute"
                />
              )}
            </div>
          </div>
          <div
            className={`inherit-parent-width ${
              window.location.pathname.includes("linkComplaint")
                ? "remaining-body-height-with-search"
                : "remaining-body-height_-128px"
            } flex-justify-content-center`}
          >
            <div className="inherit-parent-height inherit-parent-width max-width-767px overflow-x-scroll hide-scrollbar flex-justify-content-center">
              {!props.complaints.loading &&
                isValidObject(props.complaints.data) && (
                  <div className="inherit-parent-width">
                    <div
                      className="padding-left-large padding-right-large inherit-parent-width"
                      data-cy="complaints-list"
                    >
                      {Object.values(props.complaints.data)?.map(
                        (data, index) => (
                          <div className="inherit-parent-width" key={index}>
                            <Card
                              onClick={() => {
                                props.navigate(`/complaint/${data.documentId}`);
                              }}
                              index={index}
                              data={data}
                              locations={props.locations.data}
                              loading={props.locations.loading}
                            />
                          </div>
                        )
                      )}
                      {!props.complaints.searchKey &&
                        props.complaints.data &&
                        props.complaints.totalCount >
                          Object.values(props.complaints.data).length && (
                          <div className="display-flex flex-justify-content-center">
                            <div
                              onClick={props.getNextSetOfComplaints}
                              style={{
                                width: "100px",
                                backgroundColor: "rgba(0, 68, 204, 0.16)"
                              }}
                              className="display-flex flex-justify-content-center flex-align-items-center padding-default border-radius-default font-family-RHD-medium"
                            >
                              <div className="padding-right-default font-size-small">
                                {props.paginationLoading
                                  ? "Loading"
                                  : "View more"}{" "}
                              </div>
                              {props.paginationLoading ? (
                                <div
                                  style={{
                                    width: "16px",
                                    height: "16px"
                                  }}
                                >
                                  <div className="primary-loading-animation" />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    width: "16px",
                                    height: "16px",
                                    transform: "rotate(90deg)"
                                  }}
                                >
                                  <ArrowIcon />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                )}

              {!props.complaints.loading &&
                !isValidObject(props.complaints.data) && (
                  <NoComplaintsFallback />
                )}

              {props.complaints.loading && <Suspense />}
            </div>
          </div>

          {!window.location.pathname.includes("linkComplaint") && (
            <>
              <div className="flex-center-children-vertically margin-top-large margin-bottom-default margin-left-large margin-right-large">
                <hr
                  style={{ width: "100%" }}
                  className="display-inline-block border-color-secondary-lite border-top-left-right-none flex-basis-1 "
                />
                <span className=" margin-horizontal-default display-inline-block font-color-secondary font-size-small">
                  OR
                </span>
                <hr
                  style={{ width: "100%" }}
                  className="display-inline-block border-color-secondary-lite border-top-left-right-none flex-basis-1 "
                />
              </div>

              <div className="display-flex flex-justify-content-center font-size-medium padding-bottom-large">
                <div>Want to create a new complaint ? </div>
                <div
                  data-cy="create-compliant-button"
                  className="font-color-primary padding-left-default"
                  onClick={() => {
                    props.navigate("/raiseComplaint");
                  }}
                >
                  Create
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div
          className="remaining-body-height"
          data-cy="complaints-filter-screen"
        >
          <div
            style={{ height: "calc(100% - 76px)" }}
            className="inherit-parent-width padding-left-large padding-right-large padding-bottom-large overflow-scroll "
          >
            {Object.keys(filterData).map((data, index) => (
              <React.Fragment key={index}>
                <Accordion
                  filterKey={data}
                  items={props.items}
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                  // issuerData={issuerData}
                  data={filterData[data]}
                  complaints={
                    isValidObject(props.complaints.data) &&
                    Object.values(props.complaints.data)
                  }
                  theme={props.theme}
                />
              </React.Fragment>
            ))}
          </div>

          <div className=" display-flex padding-large">
            <Button
              type="button"
              className="max-width-500px margin-right-default"
              data-cy={`apply-filter-button`}
              text={"Apply"}
              onClick={() => {
                props.setFilter(selectedFilter);
                setShowFilter(false);
              }}
              disabled={
                selectedFilter?.dateRange?.from >
                  new Date().toISOString().split("T")[0] ||
                selectedFilter?.dateRange?.to <
                  selectedFilter?.dateRange?.from ||
                selectedFilter?.dateRange?.to >
                  new Date().toISOString().split("T")[0]
              }
              boxShadow={false}
            />

            <Button
              type="button"
              variant="secondary"
              className=" max-width-500px margin-left-default"
              data-cy={`reset-filter-button`}
              text="Reset"
              onClick={() => {
                resetFilter();
                setShowFilter(false);
              }}
              boxShadow={false}
            />
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    complaints: state.complaints,
    locations: state.locations
  };
};

const mapDispatchToProps = function () {
  return {
    setFilter: (data) => setFilter(data),
    setSearchKey: (data) => setSearchKey(data),
    setSortBy: (data) => setSortBy(data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Complaints);

const Card = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={`display-flex inherit-parent-width flex-justify-content-space-between ${"padding-bottom-large"}`}
      data-cy={`complaint-${props.data.documentId}`}
    >
      <div className="inherit-parent-width display-flex flex-justify-content-space-between">
        <div
          style={{
            width: "calc(100% - 100px)"
          }}
        >
          {!props.loading ? (
            <>
              <div className="font-size-medium font-family-RHD-medium padding-bottom-default inherit-parent-width text-overflow-ellipsis text-capitalize">
                {props.data.category?.toString()?.toLowerCase()}
              </div>

              <div className="font-size-smaller">
                {props.data.documentId} -{" "}
                {`${props.locations?.[
                  props.data.location.id
                ]?.PCTNo?.toString().padStart(2, "0")}
              - ${props.locations?.[props.data.location.id]?.location}`}
              </div>
            </>
          ) : (
            <div className="inherit-parent-width">
              <div className="shimmer padding-large border-radius-default inherit-parent-width" />
            </div>
          )}
        </div>

        <div className="display-flex flex-align-items-center">
          <LinkIcon />
          <div
            style={{ width: "16px", height: "12px" }}
            className="font-size-smaller display-flex flex-justify-content-center white-space-nowrap"
          >
            {props.data?.issuedBy?.linkedWith?.length}
          </div>

          <div className=" display-flex flex-align-items-center ">
            <ArrowIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

const NoComplaintsFallback = () => {
  return (
    <div
      className=" inherit-parent-height padding-horizontal-large display-flex flex-direction-column flex-justify-content-center flex-align-items-center"
      data-cy="no-complaints-fallback"
    >
      <FallbackClipArt />
      <div className=" padding-top-larger font-family-RHD-medium">
        No complaints yet
      </div>
      <div className=" padding-top-medium text-align-center font-size-medium">
        Please check after some time.
      </div>
    </div>
  );
};

const Suspense = () => {
  return (
    <div className=" inherit-parent-width padding-top-large">
      {[...Array(7)].map((_data, index) => (
        <div className=" padding-default inherit-parent-width" key={index}>
          <div className="shimmer padding-larger border-radius-default  inherit-parent-width" />
        </div>
      ))}
    </div>
  );
};

const Accordion = (props) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <div
        className={`display-flex flex-justify-content-space-between  padding-top-large padding-bottom-large ${
          show ? "" : "border-bottom-1px"
        } position-sticky top-0 background-white `}
        data-cy={`${props.filterKey}`}
        onClick={() => {
          if (show === false) {
            setShow(true);
          } else {
            setShow(false);
          }
        }}
      >
        <div
          className={`${
            show ? " " : ""
          } font-size-medium font-family-RHD-medium`}
        >
          {props.data.title}
        </div>
        <DropDownButton
          dropDownButton-data-cy="title-dropDown"
          dropDownClick={() => {
            if (show === false) {
              setShow(true);
            } else {
              setShow(false);
            }
          }}
          showData={show}
          theme={props.theme}
        />
      </div>

      {show && (
        <div className="border-bottom-1px ">
          {props.filterKey === "category" &&
            props.data?.value?.map((item, index) => (
              <div
                key={index}
                data-cy={`${
                  props.selectedFilter?.category.includes(item)
                    ? "selected-"
                    : ""
                }${props.filterKey}-${item}`}
                onClick={() => {
                  if (props.selectedFilter?.category.includes(item)) {
                    props.setSelectedFilter({
                      ...props.selectedFilter,
                      category: props.selectedFilter?.category.filter(
                        (data) => data !== item
                      )
                    });
                  } else {
                    props.setSelectedFilter({
                      ...props.selectedFilter,
                      category: [...props.selectedFilter?.category, item]
                    });
                  }
                }}
                className="padding-bottom-default padding-top-default display-flex flex-align-items-center cursor-pointer"
              >
                <div className="display-flex font-size-default flex-justify-content-center flex-align-items-center">
                  {props.selectedFilter?.category.includes(item) ? (
                    <div className="margin-right-large">
                      <TickIcon
                        color={props.theme === "dark" ? "white" : "#404040"}
                        height={16}
                        width={16}
                      />
                    </div>
                  ) : (
                    <div className="margin-right-large">
                      <TickIcon
                        color={"rgba(0, 0, 0, 0)"}
                        height={16}
                        width={16}
                      />
                    </div>
                  )}
                  <div className="font-size-medium">{item}</div>
                </div>
              </div>
            ))}
          {props.filterKey === "issueRaised" && (
            <div className="padding-top-default padding-bottom-default inherit-parent-width">
              <div className="display-flex flex-justify-content-center">
                <div className="padding-top-smaller white-space-nowrap margin-right-large font-size-small font-family-RHD-medium">
                  PHONE NUMBER
                </div>
                <div className="display-flex flex-direction-column inherit-parent-width">
                  <input
                    autoFocus={true}
                    name="phoneNumber"
                    data-cy={"phoneNumber-issueRaised"}
                    placeholder="Enter a Phone Number"
                    className={`font-color-secondary border-none inherit-parent-width font-size-medium font-family-RHD-medium `}
                    value={props.selectedFilter?.issueRaised}
                    onChange={(e) => {
                      props.setSelectedFilter({
                        ...props.selectedFilter,
                        issueRaised: e.target.value
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {props.filterKey === "dateRange" && (
            <form
              className="padding-left-larger padding-right-larger padding-top-default font-size-small font-family-RHD-medium"
              data-cy={"select-date"}
              onChange={(e) => {
                props.setSelectedFilter({
                  ...props.selectedFilter,
                  dateRange: {
                    ...props.selectedFilter?.dateRange,
                    [e.target.name]: e.target.value,
                    ...(e.target.name === "from" &&
                    !props.selectedFilter?.dateRange?.to
                      ? { to: new Date().toISOString().split("T")[0] }
                      : {}),
                    ...(e.target.name === "to" &&
                    !props.selectedFilter?.dateRange?.from
                      ? { from: e.target.value }
                      : {})
                  }
                });
              }}
            >
              <div className="display-flex flex-justify-content-center">
                <div style={{ width: 70 }} className="  padding-top-smaller">
                  FROM
                </div>
                <div className="display-flex flex-direction-column inherit-parent-width">
                  <input
                    name="from"
                    data-cy={"date-range-from"}
                    className={` ${
                      props.theme === "dark"
                        ? "font-color-white background-color-dark calendar-icon-color-invert"
                        : "font-color-secondary"
                    }  border-none inherit-parent-width font-size-medium font-family-RHD-medium `}
                    value={props.selectedFilter?.dateRange.from || ""}
                    max={
                      props.selectedFilter?.dateRange?.to ||
                      new Date().toISOString().split("T")[0]
                    }
                    type="date"
                  />
                  <div
                    style={{ height: "25px" }}
                    className="font-color-red padding-top-small font-size-small"
                  >
                    {props.selectedFilter?.dateRange.from >
                    new Date().toISOString().split("T")[0]
                      ? "Please enter a valid date"
                      : ""}
                  </div>
                </div>
              </div>

              <div className="display-flex flex-justify-content-center padding-top-default">
                <div style={{ width: 70 }} className="padding-top-smaller">
                  TO
                </div>
                <div className="display-flex flex-direction-column inherit-parent-width ">
                  <input
                    name="to"
                    data-cy={"date-range-to"}
                    className={` ${
                      props.theme === "dark"
                        ? "font-color-white background-color-dark calendar-icon-color-invert"
                        : "font-color-secondary"
                    }  border-none inherit-parent-width font-size-medium font-family-RHD-medium `}
                    value={props.selectedFilter?.dateRange.to || ""}
                    min={props.selectedFilter?.dateRange?.from || ""}
                    max={new Date().toISOString().split("T")[0]}
                    type="date"
                  />

                  <div
                    style={{ height: "25px" }}
                    className="font-color-red padding-top-small font-size-small"
                  >
                    {props.selectedFilter?.dateRange.to <
                      props.selectedFilter?.dateRange.from ||
                    props.selectedFilter?.dateRange.to >
                      new Date().toISOString().split("T")[0]
                      ? "Please enter a valid date"
                      : ""}
                  </div>
                </div>
              </div>
            </form>
          )}
          {props.filterKey === "PCTNo" &&
            props.data.value?.map((ele, index) => (
              <div
                className="padding-top-default padding-bottom-default display-flex flex-align-items-center cursor-pointer"
                data-cy={`${
                  props.selectedFilter?.PCTNo?.includes(`${ele.PCTNo}`)
                    ? "selected-"
                    : ""
                }${props.filterKey}-${ele.PCTNo} - ${ele.location}`}
                onClick={() => {
                  if (props.selectedFilter?.PCTNo?.includes(`${ele.PCTNo}`)) {
                    props.setSelectedFilter({
                      ...props.selectedFilter,
                      PCTNo: props.selectedFilter?.PCTNo?.filter(
                        (data) => !data.includes(`${ele.PCTNo}`)
                      )
                    });
                  } else {
                    props.setSelectedFilter({
                      ...props.selectedFilter,
                      PCTNo: [...props.selectedFilter?.PCTNo, `${ele.PCTNo}`]
                    });
                  }
                }}
                key={index}
              >
                <div className="display-flex font-size-larger flex-align-items-center">
                  {props.selectedFilter?.PCTNo?.includes(`${ele.PCTNo}`) ? (
                    <div className="margin-right-large">
                      <TickIcon
                        color={props.theme === "dark" ? "white" : "#404040"}
                        height={16}
                        width={16}
                      />
                    </div>
                  ) : (
                    <div className="margin-right-large">
                      <TickIcon
                        color={"rgba(0, 0, 0, 0)"}
                        height={16}
                        width={16}
                      />
                    </div>
                  )}
                  <div className="font-size-medium">
                    {`${ele.PCTNo} - ${ele.location}`}
                  </div>
                </div>
              </div>
            ))}

          {props.filterKey === "status" &&
            props.data.value?.map((ele, index) => (
              <div
                className="padding-top-default padding-bottom-default display-flex flex-align-items-center cursor-pointer"
                data-cy={`${
                  props.selectedFilter?.status?.includes(ele.toUpperCase())
                    ? "selected-"
                    : ""
                }${props.filterKey}-${ele}`}
                onClick={() => {
                  if (
                    props.selectedFilter?.status?.includes(ele.toUpperCase())
                  ) {
                    props.setSelectedFilter({
                      ...props.selectedFilter,
                      status: props.selectedFilter?.status.filter(
                        (data) => data.toUpperCase() !== ele.toUpperCase()
                      )
                    });
                  } else {
                    props.setSelectedFilter({
                      ...props.selectedFilter,
                      status: [
                        ...props.selectedFilter?.status,
                        ele.toUpperCase()
                      ]
                    });
                  }
                }}
                key={index}
              >
                <div className="display-flex font-size-larger flex-align-items-center">
                  {props.selectedFilter?.status?.includes(ele.toUpperCase()) ? (
                    <div className="margin-right-large">
                      <TickIcon
                        color={props.theme === "dark" ? "white" : "#404040"}
                        height={16}
                        width={16}
                      />
                    </div>
                  ) : (
                    <div className="margin-right-large">
                      <TickIcon
                        color={"rgba(0, 0, 0, 0)"}
                        height={16}
                        width={16}
                      />
                    </div>
                  )}
                  <div className="font-size-medium">{ele}</div>
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

const DropDownButton = (props) => {
  return (
    <div
      className={`${props.className} cursor-pointer`}
      onClick={props.dropDownClick}
      data-cy={props["dropDownButton-data-cy"]}
    >
      <div
        style={{ rotate: props.showData ? "270deg" : "90deg" }}
        className="flex-justify-content-center"
      >
        <ArrowIcon color={props.theme === "dark" ? "white" : "#404040"} />
      </div>
    </div>
  );
};
